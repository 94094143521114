import React, { useState } from 'react';
import { Button, Form, Container, Modal, Spinner, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { ImageUploadMultip,GeneratArticleByModelAuto } from "../../page/constants/index";
import toast, { Toaster } from 'react-hot-toast';
import '../../style/ModelPage.css';
import { connect } from 'react-redux';
const ImageUpload = ({ onImagesUploaded }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image); // Use the same key for all files
    });

    setIsLoading(true);
    try {
      const response = await axios.post(ImageUploadMultip, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onImagesUploaded(response.data.image_urls);
      console.log('Uploaded Image URLs:', response.data.image_urls);
      toast.success('تم رفع الصور بنجاح');
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('حدث خطأ في رفع الصور');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form.Group controlId="form-images">
      <Form.Label className="text-light">الصور</Form.Label>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Form.Control
          type="file"
          accept=".png, .jpg, .jpeg"
          multiple
          onChange={handleFileChange}
          className="bg-dark text-light"
        />
        <Button variant="primary" onClick={handleUpload} className="mt-2" disabled={isLoading}>
          {isLoading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'تحميل الصور'
          )}
        </Button>
      </div>
      <Toaster />
    </Form.Group>
  );
};

const DashboardRow = ({ ModelOption = [], LangOption = [],session }) => {
  const [formData, setFormData] = useState({
    model: '',
    images: [],
    videos: '',
    sources: '',
    tags: '',
    titles: '',
    language: '',
    keyword: '',
    article: ''
  });
  const [showModal, setShowModal] = useState({ sources: false, tags: false, videos: false });
  const [modalContent, setModalContent] = useState({ sources: '', tags: '', videos: '' });
  const [modalLanguage, setModalLanguage] = useState('العربية');
  const [videoPer, setVideoPer] = useState('');
  const [rangeValues, setRangeValues] = useState({ sources: { from: '', to: '' }, tags: { from: '', to: '' } });

  const handleInputChange = (label, value) => {
    setFormData({
      ...formData,
      [label]: value,
    });
  };

  const handleRangeChange = (label, field, value) => {
    setFormData({
      ...formData,
      [label]: {
        ...formData[label],
        [field]: value,
      },
    });
  };

  const handleModalSave = (label) => {
    if (modalContent[label]) {
      setFormData({
        ...formData,
        [label]: modalContent[label].split('\n'),
      });
    } else {
      setFormData({
        ...formData,
        [label]: [],
      });
    }
    setShowModal({ ...showModal, [label]: false });
  };

  const handleLog = async () => {
    const loggedData = {
      model: formData['model'],
      images: formData['images'],
      videos: formData['videos'],
      sources: formData['sources'],
      tags: formData['tags'],
      titles: formData['titles'],
      language: formData['language'],
      keyword: formData['keyword'],
      article: formData['article'],
    };

    // Log the data in console
    console.log('Logged Data:', loggedData);
    console.log("session", session);

    try {
      // Send the first request and wait for the response
      let response = await axios.post(GeneratArticleByModelAuto, loggedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${session}`,
        },
      });
      toast.success('تم إرسال البيانات بنجاح');
      console.log('Response from server:', response.data);

      // Loop over the remaining articles and send subsequent requests
      for (let i = 1; i < formData['article']; i++) {
        response = await axios.post(GeneratArticleByModelAuto, loggedData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${session}`,
          },
        });
        console.log(`Response from server for article ${i + 1}:`, response.data);
      }
      toast.success('تم إرسال البيانات بنجاح');
    } catch (error) {
      console.error('Error sending data to backend:', error);
      toast.error('حدث خطأ في إرسال البيانات');
    }
  };

  const handleImagesUploaded = (imageUrls) => {
    setFormData({
      ...formData,
      images: imageUrls,
    });
  };

  return (
    <Container className="p-4" style={{ backgroundColor: '#212429', borderRadius: '10px', paddingTop: "0px !important", paddingBottom: "0px !important" }}>
      <div className="row align-items-center mb-4 container-field-auto" style={{ width: "85%", flexWrap: "nowrap" }}>
        <div className="col-md-3 mb-3">
          <ImageUpload onImagesUploaded={handleImagesUploaded} />
        </div>

        <div className="col-md-2 mb-3">
          <Form.Group controlId="form-model">
            <Form.Label className="text-light">النموذج</Form.Label>
            <Form.Select
              value={formData['model']}
              onChange={(e) => handleInputChange('model', e.target.value)}
              className="bg-dark text-light"
            >
              <option value="">اختر النموذج</option>
              {ModelOption.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="col-md-2 mb-3" style={{ width: "100px " }}>
          <Form.Group controlId="form-language">
            <Form.Label className="text-light">اللغة</Form.Label>
            <Form.Select
              value={formData['language']}
              onChange={(e) => handleInputChange('language', e.target.value)}
              className="bg-dark text-light"
            >
              <option value=""> اللغة</option>
              <option value="ar">العربية</option>
              <option value="en">الانجليزية</option>
           
            </Form.Select>
          </Form.Group>
        </div>

        <div className="col-md-2 mb-3" style={{ width: "25%" }}>
          <Form.Group controlId="form-keyword">
            <Form.Label className="text-light">كلمة مفتاحية</Form.Label>
            <Form.Control
              type="text"
              value={formData['keyword'] || ''}
              onChange={(e) => handleInputChange('keyword', e.target.value)}
              className="bg-dark text-light"
            />
          </Form.Group>
        </div>
        <div className="col-md-3 mb-3" style={{ width: "84px" }}>
          <Form.Group controlId="form-titles">
            <Form.Label className="text-light">العناوين</Form.Label>
            <Form.Control
              type="number"
              value={formData['titles'] || ''}
              onChange={(e) => handleInputChange('titles', e.target.value)}
              className="bg-dark text-light"
            />
          </Form.Group>
        </div>
        <div className="col-md-3 mb-3" style={{ width: "84px" }}>
          <Form.Group controlId="form-article">
            <Form.Label className="text-light">المقالات</Form.Label>
            <Form.Control
              type="number"
              max="10"
              value={formData['article'] || ''}
              onChange={(e) => handleInputChange('article', e.target.value)}
              className="bg-dark text-light"
            />
          </Form.Group>
        </div>
      </div>
      <div className="row align-items-center mb-4 " style={{ width: "24%", justifyContent: "end", paddingRight: "5px" }}>
        <div className="col-md-6 mb-3 d-flex" style={{ width: "100%", display: "flex !important", justifyContent: "flex-end !important", gap: "10px", marginTop: "29px" }}>
          <DropdownButton id="dropdown-basic-button" title="إعدادات إضافية" variant="secondary">
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, videos: true })}>الفديوهات</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, sources: true })}>المصادر</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, tags: true })}>التاجات</Dropdown.Item>
          </DropdownButton>
          <Button variant="primary" onClick={handleLog} className="bg-dark text-light" style={{ height: "70%" }}>
            سجل البيانات
          </Button>
        </div>
      </div>

      {/* Modal for sources, tags, and videos */}
      {['sources', 'tags', 'videos'].map((label) => (
        <Modal key={label} show={showModal[label]} onHide={() => setShowModal({ ...showModal, [label]: false })}>
          <Modal.Header closeButton className="bg-dark text-light">
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            <Form.Group controlId={`modal-${label}`}>
              <Form.Label className="text-light">أدخل البيانات (سطر لكل عنصر)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={modalContent[label] || ''}
                onChange={(e) => setModalContent({ ...modalContent, [label]: e.target.value })}
                className="bg-dark text-light"
              />
            </Form.Group>
            {label === 'videos' && (
              <Form.Group controlId="modal-video-per" className="mt-3">
                <Form.Label className="text-light">فديو لكل</Form.Label>
                <Form.Control
                  type="number"
                  value={videoPer}
                  onChange={(e) => setVideoPer(e.target.value)}
                  className="bg-dark text-light"
                />
              </Form.Group>
            )}
            {['sources', 'tags'].includes(label) && (
              <div className="d-flex gap-2 mt-3">
                <Form.Label className="text-light">من</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="من"
                  value={rangeValues[label].from}
                  onChange={(e) => setRangeValues({
                    ...rangeValues,
                    [label]: { ...rangeValues[label], from: e.target.value },
                  })}
                  className="bg-dark text-light"
                />
                <Form.Label className="text-light">إلى</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="إلى"
                  value={rangeValues[label].to}
                  onChange={(e) => setRangeValues({
                    ...rangeValues,
                    [label]: { ...rangeValues[label], to: e.target.value },
                  })}
                  className="bg-dark text-light"
                />
              </div>
            )}
            {label === 'sources' && (
              <Form.Group controlId="modal-language" className="mt-3">
                <Form.Label className="text-light">اللغة</Form.Label>
                <Form.Select
                  value={modalLanguage}
                  onChange={(e) => setModalLanguage(e.target.value)}
                  className="bg-dark text-light"
                >
                   <option value="ar">العربية</option>
                <option value="en">الانجليزية</option>
           
       
                </Form.Select>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-dark text-light">
            <Button variant="secondary" onClick={() => setShowModal({ ...showModal, [label]: false })} className="bg-dark text-light">
              إغلاق
            </Button>
            <Button variant="primary" onClick={() => handleModalSave(label)} className="bg-dark text-light">
              حفظ
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(DashboardRow);
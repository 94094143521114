import React from 'react';
import {Header} from "../composant/ModelPage/index";
import DashboardRow from "../composant/ModelPageAuto/DashboardRow"
import "./App.css";

const ModelOption = ['Option 1', 'Option 2', 'Option 3'];
const LangOption = ['العربية', 'الإنجليزية', 'الفرنسية'];

function AutoModelPage() {
  return (
    <div className="home" style={{flexDirection: "column"}}>
      <Header />
      {/* Additional content can go here */}
      <DashboardRow ModelOption={ModelOption} LangOption={LangOption}/>
      
    </div>
  );
}

export default AutoModelPage;

import React, { useState } from 'react';
import { Button, Form, Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { ModelSave } from "../../page/constants/index";
import { connect } from 'react-redux';
import '../../style/ModelPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setSession, setSerpApi } from "../../page/action/index";
import toast, { Toaster } from 'react-hot-toast';
import TemplateModal from "./TemplateModal";

const Modeltable = (props) => {
  const data = {
    speacial_information: 'معلومات خاصة',
    special_word: 'كلمات خاصة',
    distinct_word_lenght: 'طول الكلمات المميزة',
    distinct_word: 'كلمات مميزة',
    top_10_word: 'اهم 10 كلمات',
    keywoard: 'الكلمة المفتاحية',
    question: 'الاسئلة',
    google_sug: 'مقترحات جوجل',
    lenght_values: 'طول القيم',
    values: 'القيم',
    lenght_entities: 'طول الكيانات',
    entities: 'الكيانات',
    count_word: 'عدد الكلمات',
    chatgp_version: 'نسخة 3.5/ 4.O',
  };

  const headers = Object.values(data);
  const [rowNumber, setRowNumber] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [paragraphCount, setParagraphCount] = useState('');
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelName, setModelName] = useState('');
  const [templateModelId, setTemplateModelId] = useState(''); // State for tracking template ID
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const yesNoFields = [
    data.speacial_information,
    data.special_word,
    data.distinct_word,
    data.top_10_word,
    data.question,
    data.google_sug,
    data.values,
    data.entities,
  ];

  const numberFields = [
    data.distinct_word_lenght,
    data.lenght_values,
    data.lenght_entities,
    data.count_word,
  ];

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newData = [...tableData];
    newData[rowIndex][colIndex] = value;
    setTableData(newData);
  };

  const handleSubmit = () => {
    const newFormattedData = tableData.slice(0, rowNumber).map((row) =>
      Object.keys(data).reduce((acc, key, colIndex) => {
        if ([data.distinct_word_lenght, data.lenght_values, data.lenght_entities].includes(data[key])) {
          // For range fields, ensure it's an object with "from" and "to"
          acc[key] = {
            from: row[colIndex]?.from || 0,
            to: row[colIndex]?.to || 0,
          };
        } else {
          // For all other fields
          acc[key] = row[colIndex] || (yesNoFields.includes(data[key]) ? "لا" : numberFields.includes(data[key]) ? 0 : key === data.chatgp_version ? "3" : "");
        }
        return acc;
      }, {})
    );

    console.log("Formatted Data for Submission:", newFormattedData);

    setFormattedData(newFormattedData);
    setLoading(true);

    if (templateModelId) {
      handleUpdate(newFormattedData);
    } else {
      axios
        .post(
          ModelSave,
          { name: modelName, rows: newFormattedData },
          {
            headers: {
              Authorization: `token ${props.session}`,
            },
          }
        )
        .then((response) => {
          toast.success("تم الحفظ بنجاح");
          setTemplateModelId(response.data.template_id); // Save new template ID if created
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            toast.error("يوجد نموذج بنفس الاسم");
          } else {
            toast.error("حدث خطأ.");
          }
          console.error("Error saving data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpdate = (newFormattedData) => {
    axios.post(ModelSave, { name: modelName, rows: newFormattedData, templateModelId }, {
      headers: {
        Authorization: `token ${props.session}`,
      }
    })
    .then(response => {
      toast.success("تم التحديث بنجاح");
      console.log("Data updated successfully:", response.data);
    })
    .catch(error => {
      toast.error("حدث خطأ.");
      console.error("Error updating data:", error);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleNewModel = () => {
    const newRowNumber = parseInt(paragraphCount, 10) || 0;
    setRowNumber(newRowNumber);
    setTableData(Array.from({ length: newRowNumber }, () => 
      headers.map((header) => yesNoFields.includes(header) ? 'لا' : numberFields.includes(header) ? 0 : header === data.chatgp_version ? '3' : '')
    ));
    setFormattedData([]);
  };

  const handelCancel = () => {
    // Clear all data, reset templateModelId, and clear table data
    setModelName('');
    setParagraphCount('');
    setTemplateModelId(''); // Reset template ID
    setRowNumber(0);
    setTableData([]);
    setFormattedData([]);
  };

  const handleSelectTemplate = (templateId, templateName) => {
    // Handle template selection from modal
    setTemplateModelId(templateId);
    setModelName(templateName);
    setShowModal(false);
  
    axios.get(ModelSave + `?id=${templateId}&action=get`, {
      headers: {
        Authorization: `token ${props.session}`,
      },
    })
    .then(response => {
      console.log("response:", response.data.rows);
      
      setModelName(response.data.template_name);
      setTemplateModelId(response.data.template_id);
      setParagraphCount(response.data.row_count + "");
      setRowNumber(response.data.rows.length);
      
      // Transform the fetched rows into the format expected by the table
      const transformedRows = response.data.rows.map(row => [
        row.speacial_information,
        row.special_word,
        { from: row.distinct_word_lenght_from || 0, to: row.distinct_word_lenght_to || 0 },
        row.distinct_word,
        row.top_10_word,
        row.keywoard,
        row.question,
        row.google_sug,
        { from: row.lenght_values_from || 0, to: row.lenght_values_to || 0 },
        row.values,
        { from: row.lenght_entities_from || 0, to: row.lenght_entities_to || 0 },
        row.entities,
        row.count_word,
        row.chatgp_version,
      ]);
  
      setTableData(transformedRows);
    })
    .catch(error => {
      toast.error("Error fetching rows for the template.");
      console.error("Error fetching rows:", error);
    });
  };
  const handleDeletTemplate = (templateId) => {
    setShowModal(false);
    axios.get(ModelSave+`?id=${templateId}&action=delete`, {
      headers: {
        Authorization: `token ${props.session}`,
      },
    })
    .then(response => {
      toast.success("تم الحذف بنجاح");
    })
    .catch(error => {
      toast.error("Error fetching rows for the template.");
      console.error("Error fetching rows:", error);
    });
  };

  return (
    <>
      <Toaster /> {/* Toaster for notifications */}
      <TemplateModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        onSelectTemplate={handleSelectTemplate}
        onDelete={handleDeletTemplate}
      />
      <div className="model-table-container-div" style={{ marginTop: "0px" }}>
        <Container className="p-4">
          <div className="box-tab p-3 bg-dark text-white" style={{ borderRadius: '8px', display: 'flex', gap: "10px", alignItems: "center", width: "100%", justifyContent: "space-around" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Group className="mb-3" controlId="modelName">
                <Form.Label>اسم النموذج</Form.Label>
                <Form.Control 
                  type="text" 
                  className="bg-dark text-light" 
                  placeholder="Enter model name"
                  value={modelName}
                  onChange={(e) => setModelName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="paragraphCount">
                <Form.Label>عدد فقرات</Form.Label>
                <Form.Control
                  type="number"
                  className="bg-dark text-light"
                  placeholder="Enter paragraph count"
                  value={paragraphCount}
                  onChange={(e) => setParagraphCount(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between" style={{ gap: '10px', marginTop: "12px" }}>
              <Button variant="success" onClick={handleSubmit} disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : (templateModelId ? 'تحديث المقال' : 'حفظ المقال')}
              </Button>
              <Button variant="danger" onClick={handelCancel}>إلغاء</Button>
              <Button variant="primary" onClick={handleNewModel}>نموذج جديد</Button>
              <Button variant="secondary" onClick={() => setShowModal(true)}>قائمة النماذج</Button>
            </div>
          </div>
        </Container>
        <Container style={{ alignItems: "flex-start", maxWidth: "95%" }}>
          <div style={{ width: "95%", maxHeight: "75vh" }}> 
            <div className="table table-dark table-bordered table-hover scroll-down-bigger" bordered hover variant="dark" style={{ maxHeight: "75vh !important", overflow: "auto", height: "75vh" }}>
              <thead style={{ position: "sticky", zIndex: "99999", top: "0px" }}>
                <tr>
                  <th className="text-center" style={{ whiteSpace: 'nowrap', color: 'white', padding: '10px' }}>الرقم</th>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="text-center"
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: '#6931a2',
                        color: 'white',
                        padding: '10px',
                        minWidth: ["طول الكلمات المميزة", "طول القيم", "طول الكيانات"].includes(header) ? "200px" : "100px",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(rowNumber)].map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="text-center" style={{ backgroundColor: '#161523', color: 'white', fontWeight: 'bold', padding: '10px' }}>{rowIndex === 0
          ? 'المقدمة'
          : rowIndex === rowNumber - 1
          ? 'الخاتمة'
          : rowIndex}</td>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex} style={{ padding: '10px', background: "#161523" }}>
                        {header === data.chatgp_version ? (
                          <Form.Select
                            className="bg-dark text-light"
                            value={tableData[rowIndex]?.[colIndex] || ''}
                            onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                            style={{ borderColor: '#33323e', color: 'white' }}
                          >
                            <option value="3">v3.5</option>
                            <option value="4">v4</option>
                          </Form.Select>
                        ) : [data.distinct_word_lenght, data.lenght_values, data.lenght_entities].includes(header) ? (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: "center" }} className='double-field'>
                              <Form.Label style={{ fontSize: 'small', color: 'white' }}>من</Form.Label>
                              <Form.Control
                                type="number"
                                className="bg-dark text-light form-control"
                                placeholder="From"
                                value={tableData[rowIndex]?.[colIndex]?.from || ''}
                                onChange={(e) => handleInputChange(rowIndex, colIndex, { from: e.target.value, to: tableData[rowIndex]?.[colIndex]?.to || '' })}
                                style={{ borderColor: '#33323e', color: 'white' }}
                              />
                              <Form.Label style={{ fontSize: 'small', color: 'white' }}>الى</Form.Label>
                              <Form.Control
                                type="number"
                                className="bg-dark text-light form-control"
                                placeholder="To"
                                value={tableData[rowIndex]?.[colIndex]?.to || ''}
                                onChange={(e) => handleInputChange(rowIndex, colIndex, { from: tableData[rowIndex]?.[colIndex]?.from || '', to: e.target.value })}
                                style={{ borderColor: '#33323e', color: 'white' }}
                              />
                            </div>
                          </>
                        ) : yesNoFields.includes(header) ? (
                          <Form.Select
                            className="bg-dark text-light"
                            value={tableData[rowIndex]?.[colIndex] || 'لا'}
                            onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                            style={{ borderColor: '#33323e', color: 'white' }}
                          >
                            <option value="نعم">نعم</option>
                            <option value="لا">لا</option>
                          </Form.Select>
                        ) : numberFields.includes(header) ? (
                          <Form.Control
                            type="number"
                            className="bg-dark text-light form-control"
                            value={tableData[rowIndex]?.[colIndex] || 0}
                            onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                            style={{ borderColor: '#33323e', color: 'white' }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            className="bg-dark text-light form-control"
                            value={tableData[rowIndex]?.[colIndex] || ''}
                            onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                            style={{ borderColor: '#33323e', color: 'white' }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

// Redux connection
const mapStateToProps = (state) => ({
  userid: state.userid,
  session: state.session,
  url: state.url,
});

const mapDispatchToProps = (dispatch) => ({
  setSession: (id) => dispatch(setSession(id)),
  setSerpApi: (api) => dispatch(setSerpApi(api)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modeltable);
